<style lang="scss" scoped>
.scroll-down-arrow {
  animation: bounce 1s ease-in-out alternate infinite;
  .first,
  .second {
    fill: white;
  }
}

@keyframes bounce {
  to {
    transform: translateY(-25%);
  }
}
</style>

<template>
  <svg
    class="scroll-down-arrow"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="first"
      d="M14.4438 24.4308C15.0449 24.1679 15.7438 24.2758 16.2375 24.7078L22.683 30.3476C23.437 31.0074 24.563 31.0074 25.317 30.3476L31.7625 24.7078C32.2562 24.2758 32.9551 24.1679 33.5562 24.4308C34.6932 24.9283 34.9265 26.4393 33.9926 27.2565L25.317 34.8476C24.563 35.5074 23.437 35.5074 22.683 34.8476L14.0074 27.2565C13.0735 26.4393 13.3068 24.9283 14.4438 24.4308Z"
      fill="#3B4D56"
    />
    <path
      class="second"
      d="M14.4438 12.4308C15.0449 12.1679 15.7438 12.2758 16.2375 12.7078L22.683 18.3476C23.437 19.0074 24.563 19.0074 25.317 18.3476L31.7625 12.7078C32.2562 12.2758 32.9551 12.1679 33.5562 12.4308C34.6932 12.9283 34.9265 14.4393 33.9926 15.2565L25.317 22.8476C24.563 23.5074 23.437 23.5074 22.683 22.8476L14.0074 15.2565C13.0735 14.4393 13.3068 12.9283 14.4438 12.4308Z"
      fill="#3B4D56"
      fill-opacity="0.5"
    />
  </svg>
</template>

<script>
export default {};
</script>
